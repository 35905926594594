.misc-wrapper {
  display: flex;
  flex-basis: 100%;
  min-height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center; }

.misc-wrapper .misc-inner {
  position: relative;
  max-width: 750px; }

.misc-wrapper .brand-logo {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 2rem;
  left: 2rem;
  margin: 0; }

.misc-wrapper .brand-logo .brand-text {
  font-weight: 600; }
